.queryButton:active{
  outline: none;
}

.queryButtonPrimary{
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  display: inline-block;
  position: relative;
  min-width: 64px;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  min-width: 88px;
  margin-left: 1rem;
  cursor: pointer;
	background-color: #3f51b5;
  background-color: var(--mdc-theme-primary, #3f51b5);
  color: white;
  color: var(--mdc-theme-text-primary-on-primary, white);
}

.queryButtonPrimary:active {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.queryButtonDelete:active{
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.queryButtonDelete{
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0.04em;
  line-height: 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  display: inline-block;
  position: relative;
  min-width: 64px;
  height: 36px;
  padding: 0 16px;
  border: none;
  border-radius: 2px;
  outline: none;
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow;
  min-width: 88px;
  margin-left: 1rem;
  cursor: pointer;
  color: #ff4081;
  color: var(--mdc-theme-accent, #ff4081);
  background-color: #ff4081;
  background-color: var(--mdc-theme-accent, #ff4081);
  color: white;
  color: var(--mdc-theme-text-primary-on-accent, white);
}

.rule{
  display: flex;
  border-radius: 3px;
  color: #9E9E9E;
  background: white;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
	margin-left: 1rem;
	padding: 0.5rem;
	margin-top: 0.2rem;
  width: 85%;
  -webkit-animation: fade 1s;
  animation: fade 1s;
  opacity: 1;
}

.rule *{
	margin-left: 0.5rem;
}

.condition{
	margin-left: 1rem;
	padding: 0.5rem;
	margin-top: 0.2rem;
  border-radius: 3px;
  color: #9E9E9E;
  background: white;
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
  width: 85%;
  -webkit-animation: fade 1s;
  animation: fade 1s;
  opacity: 1;
}

.querySelect{
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 1.75rem;
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  padding-left: 0;
  padding-right: 24px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  max-width: calc(100% - 24px);
  height: 32px;
  -webkit-transition: border-bottom-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1), background-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1);
  transition: border-bottom-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1), background-color 150ms 0ms cubic-bezier(0.4, 0, 1, 1);
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 0;
  background: none;
  background-repeat: no-repeat;
  background-position: right center;
  background-image: url("data:image/svg+xml,<svg width="10px" height="5px"><polygon points="7 10 12 15 17 10"></polygon></svg>");
  font-family: Roboto, sans-serif;
  font-size: .936rem;
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .04em;
}

.querySelect:focus{
  border-bottom-color: #3f51b5;
  border-bottom-color: var(--mdc-theme-primary, #3f51b5);
  outline: none;
  background-color: rgba(0, 0, 0, 0.06);
}

.queryInput{
  color: rgba(0, 0, 0, 0.87);
  color: var(--mdc-theme-text-primary-on-light, rgba(0, 0, 0, 0.87));
  border: none;
  background: none;
  font-size: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-transition: border-bottom-color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: border-bottom-color 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-top: 8px;
  padding-bottom: 6px;
}

.queryInput:focus{
  border-color: #3f51b5;
  border-color: var(--mdc-theme-primary, #3f51b5);
  outline: none;
}

.queryText{
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  letter-spacing: 0.04em;
  display: inline-block;
  margin-bottom: 8px;
  will-change: opacity, transform, color;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: initial;
  -webkit-transition: none;
  transition: none;
}

@-webkit-keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}@keyframes fade {
  from {opacity: 0}
  to {opacity: 1}
}

.error {
  color: red;
}
